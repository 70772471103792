<template>
  <div style="padding-top: 64px;padding-bottom:200px;" class="flex-column">
    <h1 class="text-center pa-5">Contact Us</h1>
    <v-row class="justify-center pl-5 pr-5">
      <v-col class="col-md-6 justify-center" cols="12">
        <p class="text-h6 font-italic ma-5">
          If you have any questions about your production project. Connect with
          us via:
        </p>
        <v-row class="justify-center">
          <v-col class="col-md-6" cols="6">
            <v-card
              class="text-center  pa-5"
              outlined
              color="#F5F5F5"
              href="mailto:support@top0.com"
            >
              <v-icon x-large color="green darken-1">mdi-email</v-icon>
              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Email
              </p>
            </v-card>
          </v-col>
          <v-col class="col-md-6" cols="6">
            <v-card
              class="text-center pa-5"
              color="#F5F5F5"
              outlined
              href="https://twitter.com/top0Tech"
            >
              <v-icon x-large color="blue darken-1">mdi-twitter</v-icon>
              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Twitter
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="col-md-6" cols="6">
            <v-card class="text-center pa-5" color="#F5F5F5" outlined>
              <v-icon x-large color="indigo darken-1">mdi-facebook</v-icon>

              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Fackbook
              </p>
            </v-card>
          </v-col>
          <v-col class="col-md-6" cols="6">
            <v-card class="text-center pa-5" color="#F5F5F5" outlined>
              <v-icon x-large color="purple darken-1">mdi-instagram</v-icon>
              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Instagram
              </p>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col class="col-md-6" cols="6">
            <v-card
              class="pa-5 text-center"
              color="#F5F5F5"
              outlined
              @click="gotoKnow()"
            >
              <svg
                class="icon"
                cla
                aria-hidden="true"
                style="font-size:2.4em;color:#6D4C41"
              >
                <use :xlink:href="'#icon-query-knowledge'"></use>
              </svg>

              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Knowledge
              </p>
            </v-card>
          </v-col>
          <v-col class="col-md-6" cols="6">
            <v-card
              class="text-center pa-5"
              outlined
              color="#F5F5F5"
              href="https://www.youtube.com/channel/UC16pLqBFE_WgEspMhkkATKQ"
            >
              <v-icon x-large color="red darken-1">mdi-youtube</v-icon>
              <p
                class="font-weight-bold font-italic text-center text-h7"
                style="padding-top:20px "
              >
                Youtube
              </p>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="col-md-6 text-left" cols="12" style="margin-top:0px">
        <p class="text-h6 font-italic ma-5">
          Or you can leave a message and we will reply in time:
        </p>
        <v-card-text>
          <v-container>
            <v-row class="pa-0">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="First Name"
                  outlined
                  v-model="fname"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Last Name"
                  outlined
                  v-model="lname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Email"
                  prepend-inner-icon="mdi-email"
                  outlined
                  required
                  :rules="emailRules"
                  v-model="_email"
                  suffix="*"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-radio-group v-model="rval" row mandatory>
                  <v-radio label="After sales problems" value="1"></v-radio>
                  <v-radio label="Pre sales consultation" value="0"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-select
                  label="Product Type"
                  item-text="typename"
                  item-value="typeid"
                  :items="ptItems"
                  dense
                  v-model="ptSelected"
                  outlined
                  @change="pmChange()"
                ></v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                  label="Product Model"
                  item-text="productmodel"
                  :items="pmItems"
                  dense
                  v-model="pmSelected"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Serial Number"
                  outlined
                  v-model="snNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Third party platform order code"
                  outlined
                  v-model="tcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="what do you want to tell us?"
                  value=""
                  v-model="text_area"
                  :rules="msgRules"
                  required
                  suffix="*"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Where are you from?"
                  outlined
                  v-model="con"
                ></v-text-field>
              </v-col>
              <p class="caption font-italic">* means this option is Required</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mt-1"></v-divider>
        <v-card-actions style="position:relative">
          <v-alert
            style="position:fixed;bottom:20px"
            v-model="alert"
            dense
            color="red"
            type="info"
            border="left"
            transition="scroll-y-reverse-transition"
            dismissible
            >{{ alertmsg }}</v-alert
          >
          <v-alert
            style="position:fixed;bottom:20px"
            v-model="sendAlert"
            dense
            color="success"
            type="success"
            border="left"
            transition="scroll-y-reverse-transition"
            dismissible
            >{{ sendmsg }}</v-alert
          ><v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="sendMsg()"
            :loading="issendMsg"
            large
          >
            Send </v-btn
          ><v-spacer></v-spacer>
        </v-card-actions>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  components: {},
  data: () => ({
    //
    sendAlert: false,
    sendmsg: null,
    alertmsg: null,
    alert: false,
    msgRules: [
      (v) => !!v || "Message is required",
      (v) => v.length > 20 || "Message must be more than 20 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    pmSelected: "",
    ptItems: null,
    pmItems: null,
    issendMsg: false,
    text_area: "",
    fname: "",
    lname: "",
    _email: "",
    tcode: "",
    con: "",
    snNumber: "",
    selectedItem: 0,
    rval: 1,
  }),
  mounted() {
    var that = this;
    that.getProductType();
  },
  methods: {
    gotoKnow() {
      this.$router.push({
        path: "Knowledge",
      });
    },
    //获取产品类型
    getProductType() {
      var that = this;

      axios.get("api/ProductInfo/GetProductTypeList").then(function(response) {
        that.ptItems = response.data.data;
      });
    },
    pmChange() {
      var that = this;
      axios
        .get("api/ProductInfo/GetProductListByTypeId?typeid=" + that.ptSelected)
        .then(function(response) {
          that.pmItems = response.data.data;
        });
    },
    sendMsg() {
      var that = this;
      that.issendMsg = true;
      if (that._email == "" || that.text_area == "") {
        that.alertmsg = "some options are required";
        that.alert = true;
        that.issendMsg = false;
      } else {
        let params = new FormData();
        params.append("firstname", that.fname);
        params.append("lastname", that.lname);
        params.append("email", that._email);
        params.append("isaftersale", parseInt(that.rval));
        params.append("orderid", that.tcode);
        params.append("productmodel", that.pmSelected);
        params.append("remarkcontent", that.text_area);
        params.append("serialnumber", that.snNumber);
        params.append("country", that.con);
        axios.post("api/Remark/Add", params).then(function(response) {
          if (response.data.code == 1) {
            that.content = response.data.data;
            that.issendMsg = false;
            that.sendmsg = "send message successful";
            that.sendAlert = true;
          }
        });
      }
    },
  },
};
</script>
