<template>
  <div style="padding-top: 64px;padding-bottom:200px;" class="flex-column">
    <h1 class="text-center pa-10">top0 Downloads</h1>
    <v-row class="justify-center pl-16 pr-16">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            top0 Camera Tool
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card tile elevation="0">
              <v-card-subtitle>
                Advanced top0 camera controls. With pan, tilt, lens, image,
                color, power, OSD, live video preview, panoramic preset, IP
                configuration, firmware upgrade and more support.
              </v-card-subtitle>

              <v-expand-transition>
                <div>
                  <v-divider></v-divider>
                  <v-row>
                    <v-col class="col-md-5"
                      ><v-card-subtitle class="font-weight-bold">
                        Why Update?
                      </v-card-subtitle>
                      <v-card-text>
                        Fully integrated configuration & control; new support
                        for Xbox and Playstation gamepad control; new support
                        for advanced functions of PTZoptics cameras (Motion
                        Sync, Pan/Tilt Limits, Focus Lock); Some bug fixes.
                      </v-card-text></v-col
                    >
                    <v-col class="col-md-7" style="padding-top:40px">
                      <h4>SoftVersion：7.02.007</h4>
                      <h4>LastUpdate：2022-09-07</h4>
                      <h4>
                        OS：
                      </h4>
                      <v-select
                      v-model="osSel"
                        :items="osItems"
                        label="select OS"
                        solo
                        dense
                        outlined
                        flat
                        style="width:90%"
                      ></v-select>
                      <v-btn
                        class="ma-2"
                        outlined
                        fab
                        color="success"
                        @click="download()"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                      <p class="font-italic subtitle-2" style="color:red">
                        <span>More platform versions will Coming Soon!!</span>
                        Before Release, we recommend using the application of
                        PTZOptics. top0 camera is fully compatible with
                        PTZoptics application.
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Document
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card tile elevation="0">
              <v-card-subtitle>
                A compilation of product data sheets and manuals
              </v-card-subtitle>
              <v-card-actions>
                <v-select
                  label="Product Type"
                  item-text="typename"
                  item-value="typeid"
                  :items="ptItems"
                  dense
                  v-model="ptSelected"
                  outlined
                  @change="pmChange()"
                ></v-select>
                <v-select
                  label="Product Model"
                  item-text="productmodel"
                  :items="pmItems"
                  dense
                  v-model="pmSelected"
                  outlined
                  style="margin-left:10px"
                ></v-select>
              </v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="downloadUM()"
                >User Manual<v-icon>mdi-download</v-icon>
              </v-btn>

              <v-btn color="success" text @click="downloadDS()"
                >DataSheet<v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            OBS DOCK
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card tile elevation="0">
              <v-card-subtitle>
                (Coming soon!)Simple top0 camera controls for OBS Dock. With
                pan, tilt, lens, image, color, power, OSD, panoramic preset and
                more support.
              </v-card-subtitle>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="success" text @click="show = !show"
                  >download<v-icon>mdi-download</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    osItems: ["WINDOWS","MacOS"],
    ptItems: null,
    pmItems: null,
    pmSelected: "",
    osSel:""
  }),
  mounted() {
    var that = this;
    that.getProductType();
  },
  methods: {
    download() {
      var that=this;
      if(that.osSel=="WINDOWS"){
        window.open(
        "https://api.top0.com/api/Download/top0CameraTool?platform=windows&version=1.0.0"
      );
      }
      if(that.osSel=="MacOS"){
        alert('Please note that the current MacOS version is a beta version and does not support video preview at this time. We will update it to support camera video preview soon.')
        window.open(
        "https://api.top0.com/api/Download/top0CameraTool?platform=macos&version=1.0.0"
      );
      }
      if(that.osSel==""){
        alert("Please Choose OS below")
      }
      
    },
    getProductType() {
      var that = this;

      axios.get("api/ProductInfo/GetProductTypeList").then(function(response) {
        that.ptItems = response.data.data;
      });
    },
    pmChange() {
      var that = this;
      axios
        .get("api/ProductInfo/GetProductListByTypeId?typeid=" + that.ptSelected)
        .then(function(response) {
          that.pmItems = response.data.data;
        });
    },
    downloadUM() {
      var that = this;
      window.open(
        "https://api.top0.com/api/Download/getUserManual?productModel=" +
          that.pmSelected
      );
    },
    downloadDS() {
      var that = this;
      window.open(
        "https://api.top0.com/api/Download/getDatasheet?productModel=" +
          that.pmSelected
      );
    },
  },
};
</script>
