<template>
  <div>
    <div style="padding-top: 56px">
      <div style="position:relative">
        <v-img
          src="../../img/top0Support.jpg"
          style="width:100%"
          v-show="$vuetify.breakpoint.mdAndUp"
        />
        <v-img
          src="../../img/top0Support_Mobile.jpg"
          style="width:100%;"
          v-show="$vuetify.breakpoint.smAndDown"
        />
        <div
          class="search-txt text-center"
          style="position:absolute;width:40%;top:70%;left:50%;margin-left:-20%"
          v-show="$vuetify.breakpoint.mdAndUp"
        >
          <h1 style="color:#fff">Welcome to TOP0 Support</h1>
          <v-text-field
            flat
            rounded
            solo
            label="How can we help you ?"
            append-icon="mdi-magnify"
            class="ma-10"
            v-model="searchtext"  
          prepend-inner-icon="mdi-magnify"
          hide-details
          @input="showSearch()"
          @focus="showSearch()"
          @click="showSearch()"
          @blur="hideSearch()"
          :loading="isSearchload"
          ></v-text-field>
          <transition name="bounce">
          <div style="position:relative" v-show="isSearch" class="text-left">
            <v-card
              class="mx-auto"
              tile
              style="position:absolute;z-index:10;top:-10px;width:100%;height: 400px;overflow: auto;"
              elevation="20"
              dark
            >
              <v-list dense flat >
                <v-subheader
                  >Search
                  <span style="padding:0 4px;color:#66BB6A;font-weight:bold">{{
                    count
                  }}</span>
                  Results</v-subheader
                >
                <v-list-item-group v-model="selectedItem" color="success">
                  <v-list-item
                    two-line
                    v-for="art in searchArt"
                    :key="art.articleid"
                    @click="readArtical(art.articleid)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        art.articletitle
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        art.updatetime
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
        </transition>
        </div>
        <div
          class="search-txt text-center"
          style="position:absolute;width:80%;top:50%;left:50%;margin-left:-40%"
          v-show="$vuetify.breakpoint.smAndDown"
        >
          <h1 style="color:#fff">Welcome to TOP0 Support</h1>
          <v-text-field
            flat
            rounded
            solo
            label="How can we help you ?"
            append-icon="mdi-magnify"
            class="ma-10"
            v-model="searchtext"  
          prepend-inner-icon="mdi-magnify"
          hide-details
          @input="showSearch()"
          @focus="showSearch()"
          @click="showSearch()"
          @blur="hideSearch()"
          :loading="isSearchload"
          ></v-text-field>
          <transition name="bounce">
          <div style="position:relative" v-show="isSearch" class="text-left">
            <v-card
              class="mx-auto"
              tile
              style="position:absolute;z-index:10;top:-10px;width:100%;height: 400px;overflow: auto;"
              elevation="20"
              dark
            >
              <v-list dense flat >
                <v-subheader
                  >Search
                  <span style="padding:0 4px;color:#66BB6A;font-weight:bold">{{
                    count
                  }}</span>
                  Results</v-subheader
                >
                <v-list-item-group v-model="selectedItem" color="success">
                  <v-list-item
                    two-line
                    v-for="art in searchArt"
                    :key="art.articleid"
                    @click="readArtical(art.articleid)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        art.articletitle
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        art.updatetime
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
        </transition>
        </div>
      </div>
    </div>
    <div style="padding-top:100px;padding-bottom:300px">
      <v-row class="justify-center">
        <v-card
          class="ma-5 pa-10 text-center"
          color="#F5F5F5"
          outlined
          min-width="300"
          @click="gotoKnow()"
        >
          <!-- <v-icon x-large color="orange">mdi-beer</v-icon> -->
          <svg class="icon ma-5" aria-hidden="true" style="font-size:5em">
            <use :xlink:href="'#icon-query-knowledge'"></use>
          </svg>
          <p
            class="font-weight-bold font-italic text-center text-h6"
            style="padding-top:20px "
          >
            Knowledge Base
          </p>
        </v-card>
        <v-card
          class="ma-5 text-center pa-10"
          outlined
          min-width="300"
          color="#F5F5F5"
          @click="gotoDownload()"
        >
          <svg class="icon ma-5" aria-hidden="true" style="font-size:5em">
            <use :xlink:href="'#icon-download'"></use>
          </svg>
          <p
            class="font-weight-bold font-italic text-center text-h6"
            style="padding-top:20px "
          >
            Downloads
          </p>
        </v-card>
        <v-card
          class="ma-5 text-center pa-10"
          color="#F5F5F5"
          outlined
          min-width="300"
          @click="gotoWarranty()"
        >
          <svg class="icon ma-5" aria-hidden="true" style="font-size:5em">
            <use :xlink:href="'#icon-extendwarranty'"></use>
          </svg>
          <p
            class="font-weight-bold font-italic text-center text-h6"
            style="padding-top:20px "
          >
            Warranty
          </p>
        </v-card>
        <v-card
          class="ma-5 text-center pa-10"
          color="#F5F5F5"
          outlined
          min-width="300"
          href="mailto:support@top0.com"
        >
          <svg class="icon ma-5" aria-hidden="true" style="font-size:5em">
            <use :xlink:href="'#icon-shouye'"></use>
          </svg>

          <p
            class="font-weight-bold font-italic text-center text-h6"
            style="padding-top:20px "
          >
            Contract Us
          </p>
        </v-card>
      </v-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      searchtext: "",
      isSearchload: false,
      isSearch: false,
      selectedItem: null,
      searchArt: null,
      count: "",
      isload: false,
    };
  },
  mounted() {
  
    this.isload = true;
    window.addEventListener("scroll", () => {
      this.isSearch = false;
    });
  },
  methods: {
    readArtical(artID) {
      this.$router.push({
        path: `/Artical/${artID}`,
      });
    },
    gotoDownload() {
      this.$router.push({
        path: "Download",
      });
    },
    gotoKnow() {
      this.$router.push({
        path: "Knowledge",
      });
    },
    gotoContact() {
      // this.$router.push({
      //   path: "Contact",
      // });
      // window.location.href="mailto:support@top0.com"
    },
    gotoWarranty() {
      this.$router.push({
        path: "Warranty",
      });
    },
    showSearch() {
      var that = this;
      that.isSearchload = true;
      if (that.searchtext != "") {
        axios
          .get("api/Knowledge/SearchArticleList?key=" + that.searchtext)
          .then(function(response) {
            if (response.data.code == 1) {
              that.isSearch = true;
              that.isSearchload = false;
              // that.searchArt = response.data.data.slice(0, 5);
              that.searchArt = response.data.data;
              that.count = response.data.data.length;
            } else {
              that.searchArt = null;
              that.isSearch = false;
              that.isSearchload = false;
            }
          });
      } else {
        that.isSearch = false;
        that.searchArt = null;
        that.isSearchload = false;
      }
    },
    hideSearch() {
      var that = this;
      setTimeout(() => {
        that.isSearch = false;
        that.isSearchload = false; //娃娃消失
      }, 100);
    },
  },
};
</script>
<style scoped>
  .bounce-enter-active {
    animation: bounce-in 0.5s;
    z-index: 5;
  }
  /* .bounce-leave-active {
    animation: bounce-in 0.5s reverse;
    z-index: 5;
  } */
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  </style>
  
