<template>
  <div>
    <div
      style="width:100%;background-color:#fff;position:fixed;z-index:2;padding-top: 64px"
    >
      <v-row class="pa-4 align-center d-flex justify-center">
        <v-col class="col-md-2 text-center">
          <h2>RC26</h2>
        </v-col>
        <v-col
          class="col-md-8 text-center"
          v-show="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn text @click="$vuetify.goTo(0, options)">
            OverView
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#how', options)">
            Tech Specs
          </v-btn>

          <v-btn text @click="$vuetify.goTo('#faq', options)">
            FAQ
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#down', options)">
            Downloads<v-icon right dark>
              mdi-download
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-md-2 text-center">
          <v-btn
            elevation="0"
            large
            rounded
            color="success"
            @click="$vuetify.goTo('#buy', options)"
          >
            Buy Now<v-icon right>
              mdi-cart
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="$vuetify.breakpoint.smAndDown">
        <v-col class="mb-2 text-center" cols="12">
          <v-btn text @click="$vuetify.goTo(0, options)" small>
            OverView
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#how', options)" small>
            Tech Specs
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#faq', options)" small>
            FAQ
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#down', options)" small>
            Downloads
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
    <div style="padding-top:250px;padding-bottom:250px">
      <v-row>
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">OverView</h1></v-col
        >
      </v-row>
      <!-- <v-row>
        <v-col class="col-md-12 pa-10">
          <h3 class="text-left">DESIGNED FOR SERIOUS STREAMERS</h3>
          <p>
            Connect with superior clarity every time you go live on channels
            like Twitch and YouTube. Stream anything you want in your choice of
            Full 1080p at 30fps or hyperfast HD 720p at 60fps. Broadcast
            masterfully with reliable no-drop audio, autofocus, and a 78°
            diagonal field of view. Includes free 3-month premium XSplit
            license. C922 streaming webcam
          </p></v-col
        >
      </v-row> -->
      <v-row>
        <!-- <v-col class="col-md-8 pa-10" cols="12">
          <h3 class="text-left">FULL HIGH-DEF 1080P STREAMING</h3>
          <p>
            Stream and record vibrant, true-to-life video. C922 features a glass
            lens with autofocus and a 78° diagonal field of view. Full HD
            streaming captures all the details, bright, natural colors, and
            fluid video at 1080p/30fps—and in HD at 720p/60fps. Use Capture to
            zoom, pan, and edit. Full High-def streaming Hpyerfast frame rate
            streaming webcamStream and record vibrant, true-to-life video. C922
            features a glass lens with autofocus and a 78° diagonal field of
            view. Full HD streaming captures all the details, bright, natural
            colors, and fluid video at 1080p/30fps—and in HD at 720p/60fps. Use
            Capture to zoom, pan, and edit. Full High-def streaming Hpyerfast
            frame rate streaming webcamStream and record vibrant, true-to-life
            video. C922 features a glass lens with autofocus and a 78° diagonal
            field of view. Full HD streaming captures all the details, bright,
            natural colors, and fluid video at 1080p/30fps—and in HD at
            720p/60fps. Use Capture to zoom, pan, and edit. Full High-def
            streaming Hpyerfast frame rate streaming webcam
          </p>
        </v-col> -->
        <v-col class="col-md-12 pa-10" cols="12">
          <img src="../../img/RC26.png" style="width:100%"
        /></v-col>
      </v-row>

      <v-row id="how">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            Tech Specs
          </h1></v-col
        >
      </v-row>
      <v-row>
        <v-col class="col-md-12 d-flex justify-center">
          <img src="../../img/RC26.png" style="width:200px" />
        </v-col>
      </v-row>
      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Basic</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Model</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">RC26</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Lens Zoom</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">12X</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Color</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Black / White</p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Video Outputs</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Outputs</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1:NDI|HX® (1080p@60fps capable)*1 <br data-v-eabb6afc="" />
                2:USB 3.0 (up to 1080P@60fps capable) <br data-v-eabb6afc="" />
                3:HDMI Version 1.4 (up to 1080p@60fps capable)
                <br data-v-eabb6afc="" />
                4:Video over Ethernet (1080p@60fps capable)*2
                <br data-v-eabb6afc="" />
                (*1:NDI4.5 current firmware support, IP first stream must be set
                to H.264 decoding) <br data-v-eabb6afc="" />
                (*2:H.264, H.265, MJPEG Encoding; RTSP; RTMP; NDI|HX® support)
                <br data-v-eabb6afc="" />
                (*3:Because USB and IP first stream use the same channel, so
                IP/NDI and USB cannot be used at the same time. Unless the IP
                stream uses the second stream channel.)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Connectors</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1:USB 3.0 <br data-v-eabb6afc="" />
                2: HDMI v1.4 Type A (Female) <br data-v-eabb6afc="" />
                3: 10/100/1000 RJ-45 <br data-v-eabb6afc="" />
                4: Non-powered 3.5mm Female Input(Line-in)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Aspect Ratios</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">4:3, 16:9</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Standard Resolutions</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                PAL, PAL wide, NTSC, NTSC Wide
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Frame Rate</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Up to 60 fps</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">
                Recommended maximum Cable Length for USB 3.0 (based on
                standards)
              </h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">9 ft</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">
                Recommended maximum Cable Length for HDMI (based on standards)
              </h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">45 ft</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">
                Recommended maximum Cable Length for Ethernet (based on
                standards)
              </h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">328 ft</p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Camera and Lens</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Sensor</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Panasonic 1/2.8” CMOS, 2.07 Megapixels
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Scanning Mode</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Progressive</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Focal Length</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                12x, f3.5mm ~ 42.3mm, F1.8 ~ F2.8
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Lens Zoom</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">12x Optical</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Digital Zoom</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">16x</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Field of View</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">72.5°</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Frame Rates</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1080p/60, 1080p/50, 1080i/60, 1080i/50, 1080p/30, 1080p/25,
                720p/60, 720p/50, 720p/30, 720p/25
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Min Lux</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                0.5 Lux @ (F1.8, AGC ON)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Shutter Speed</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1/30s ~ 1/10000s
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">SNR</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">≥55dB</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Digital Noise Reduction</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                2D&3D Digital Noise Reduction
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Wide Dynamic Range</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">White Balance</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Auto, Indoor, Outdoor, One Push, Manual, VAR
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Horizontal Angle of View</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                6.9° (tele) to 72.5° (wide)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Vertical Angle of View</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                3.9° (tele) to 44.8°(wide)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Vertical Flip & Mirror</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">HDMI Image Freeze</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Backlight Compensation</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Pan & Tilt Movement</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Pan Movement</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">±170°</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Tilt Rotation</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Up: 90°, Down: 30°
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Pan Speed Range</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">1.7° ~ 100°/s</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Tilt Speed Range</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">1.7° ~ 69.9°/s</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Presets</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                10 via IR (255 via Serial or IP)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Preset Accuracy</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">0.1°</p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Networking</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">DHCP</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Static IP</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Multicast</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Dual Streaming</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">HTTP Port Number</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Default 80</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">RTSP Port</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Default 554</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">PTZ Port</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Default 5678</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">UDP Port</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Default 1259</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Web Management</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Support <br data-v-eabb6afc="" />
                Guest & Admin Logins
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Audio</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Analog audio inputs</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Line In, 3.5mm (Not Mic-in, HDMI & USB & IP Stream Support)
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">IPC Features</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Compression</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                H.265/H.264/MJEPG
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Stream</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Main Stream, Sub Stream
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Main Stream Resolution</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1920x1080,1280x720,1024x576,960x540,640x480,640x360
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Sub Stream Resolution</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                1280x720,1024x576,720x576,
                720x480,720x408,640x360,480x270,320x240,320x180
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Bit Rate</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                32Kbps ~ 102400Kbps
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Bit Rate Type</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Variable Rate / Fixed Rate
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Frame Rate</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                50Hz: 1fps ~ 50fps, 60Hz: 1fps ~ 60fps
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Audio Compression</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">AAC</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Audio Bit Rate</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                96Kbps, 128Kbps, 256Kbps
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Support protocols</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                TCP/IP, HTTP, RTSP, RTMP, Onvif, DHCP, Multicast, etc.
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">USB Featured</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Operate System</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Windows, Mac OS, Linux
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Color System</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">YUV 4:2:2</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Video Format</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                USB3.0: 1080p/60, 1080p/50, 1080p/30, 1080p/25, 720p/60,
                720p/50, 720p/30, 720p/25 <br data-v-eabb6afc="" />
                USB2.0: 1080p/10, 720p/25, 960x540p/30, 960x540p/25,
                640x360p/60, 640x360p/50
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">UVC Protocol</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">UVC 1.1</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">UVC PTZ</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">Support</p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Camera Control</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">8-pin mini din In</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                RS-232, VISCA, PELCO-D/P
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Baud Rate</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                2400/4800/9600 bits
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">IP</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">VISCA over IP</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">USB</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">UVC1.1</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">
                Recommended maximum cable length for RS-232
              </h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                90 feet (30 meters)
              </p>
            </div>
          </div>
        </div>
      </div>

      <hr
        data-v-eabb6afc=""
        role="separator"
        aria-orientation="horizontal"
        class="v-divider theme--light"
      />

      <div data-v-eabb6afc="" class="row pa-10">
        <div data-v-eabb6afc="" class="col-md-2 text-center col col-12">
          <h2 data-v-eabb6afc="">Electrical Index</h2>
        </div>
        <div data-v-eabb6afc="" class="col-md-10 col">
          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Power Supply</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">12W (Max)</p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Input Voltage</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                12V DC (10.8 – 13.0V DC) or PoE 802.3af or USB(5V)
              </p>
            </div>
          </div>

          <div data-v-eabb6afc="" class="row clearfix">
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <h4 data-v-eabb6afc="">Power over Ethernet</h4>
            </div>
            <div data-v-eabb6afc="" class="col-md-6 text-left col">
              <p data-v-eabb6afc="" class="font-weight-light">
                Standard PoE supported
              </p>
            </div>
          </div>
        </div>
      </div>

      <v-row id="down">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            Downloads
          </h1></v-col
        >
      </v-row>
      <v-row class="pa-4 justify-center">
        <v-col class="col-md-4" cols="12">
          <v-card outlined>
            <v-card-title>
              top0 Camera Tool
            </v-card-title>
            <v-card-subtitle>
              Advanced top0 camera controls. With pan, tilt, lens, image, color,
              power, OSD, live video preview, panoramic preset, IP
              configuration, firmware upgrade and more support.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="orange lighten-2" text @click="show = !show"
                >download
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-row>
                  <v-col class="col-md-5"
                    ><v-card-subtitle class="font-weight-bold">
                      Why Update?
                    </v-card-subtitle>
                    <v-card-text>
                      Fully integrated configuration & control; new support for
                      Xbox and Playstation gamepad control; new support for
                      advanced functions of PTZoptics cameras (Motion Sync,
                      Pan/Tilt Limits, Focus Lock); Some bug fixes.
                    </v-card-text></v-col
                  >
                  <v-col class="col-md-7" style="padding-top:40px">
                    <h4>SoftVersion：7.01.002</h4>
                    <h4>LastUpdate：2021-11-20</h4>
                    <h4>
                      OS：
                    </h4>
                    <v-select
                      flat
                      disabled
                      label="windows"
                      solo
                      dense
                      outlined
                      style="width:80%"
                    ></v-select>
                    <v-btn
                      class="ma-2"
                      outlined
                      fab
                      color="success"
                      @click="download()"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <p class="font-italic subtitle-2" style="color:red">
                      <span>More platform versions will Coming Soon!!</span>
                      Before Release, we recommend using the application of
                      PTZOptics. top0 camera is fully compatible with PTZoptics
                      application.
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col class="col-md-4" cols="12">
          <v-card class="ma-1" outlined>
            <v-card-title>
              Document
            </v-card-title>
            <v-card-subtitle>
              A compilation of product data sheets and manuals
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="downloadUM()"
                >User Manual<v-icon>mdi-download</v-icon>
              </v-btn>

              <v-btn color="success" text @click="downloadDS()"
                >DataSheet<v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-md-4" cols="12">
          <v-card class="ma-1" outlined>
            <v-card-title>
              OBS DOCK
            </v-card-title>
            <v-card-subtitle>
              (Coming soon!)Simple top0 camera controls for OBS Dock. With pan,
              tilt, lens, image, color, power, OSD, panoramic preset and more
              support.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="show = !show"
                >download<v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pa-10 " style="background-color:#212121" id="buy">
        <v-col class="col-md-8" cols="12">
          <img src="../../img/RC26.png" style="width:100%" />
        </v-col>
        <v-col class="col-md-4">
          <v-card class="mx-auto" elevation="0" color="transparent" dark>
            <v-card-text>
              <div style="padding-bottom:50px">
                <p class="text-h2 font-weight-bold" style="color:#fff">
                  ROCWARE RC26
                </p>
                <p>
                  1080P NDI PTZ camera with HDMI, USB3.0 and IP video output
                </p>
              </div>
              <!-- <p class="text-h4" style="color:#fff">
                <v-icon color="amber darken-1" style="padding-right:20px"
                  >mdi-sale</v-icon
                >$799
              </p> -->
              <p class="font-italic">
                12x optical zoom. Available with black and white
              </p>
            </v-card-text>
            <v-divider dark></v-divider>
            <v-card-actions>
              <v-btn
                color="success"
                rounded
                style="margin:50px auto"
                @click="gotoam()"
              >
                Buy From Amazon.com
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-10" id="faq">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            FAQ - ROCWARE RC26
          </h1></v-col
        >
      </v-row>
      <v-row class="pa-10">
        <v-col class="col-md-12 mx-auto text-center" cols="12">
          <v-expansion-panels>
            <v-expansion-panel v-for="item in faqitems" :key="item.ques">
              <v-expansion-panel-header style="color:#1565C0">
                {{ item.ques }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.answ }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isActive: true,
    show: false,
    options: {
      offset: 240,
    },
    osItems: ["WINDOWS"],
    faqitems: [
      {
        ques: "Does this camera support NDI?",
        answ:
          "Yes. This camera support NDI 4.5. For getting the best experience, you must set the first IP video stream to H.264 encoding.",
      },
      {
        ques: "Can i use usb,hdmi and ip video output at the same time?",
        answ:
          "Yes.It can only be used in: HDMI+USB+IP (NDI/RTSP/RTMP, only second stream) mode, but it is not recommended.Because USB video and the first stream of IP video use the same channel, when USB and IP (NDI/RTSP/RTMP) need to be used at the same time, the IP video stream can only use the second stream channel(Please note that the second stream of IP video supports up to 720p). So the recommended video output matching is: HDMI+USB3.0 or HDMI+IP(NDI/RTSP/RTMP).",
      },
      {
        ques:
          "Is it possible to power my ptz camera with a usb 3.0 connection?",
        answ: "Yes.This camera can be powered via using a USB connection.",
      },
      {
        ques: "Can this ptz camera work with MICROSOFT TEAMS or ZOOM?",
        answ:
          "Yes. This camera can work with any video conferencing software just like a webcam via USB connection. And you can also import NDI® video sources directly into your favorite video conferencing software over the network. The NDI® Virtual input tool can create a virtual USB camera source on your computer that you can select as your video source in your conferencing software.",
      },
      {
        ques: "Does this ptz camera support poe?",
        answ: "Yes. This camera supports Power over Ethernet (802.3af)",
      },
      {
        ques:
          "Can i control this camera by using a video conferencing software like ZOOM?",
        answ:
          "Yes. This camera supports standard UVC camera controls, controlling your PTZ camera from a Zoom Room iPad or in a far end camera control workflow. Besides, control this camera using most UVC-enabled software via a single USB connection to your computer, such as top0 Camera Tool for Windows.",
      },
      {
        ques: "Can these ptz cameras be controlled over the network?",
        answ:
          "Yes. Once this camera is connected to the network you can control the pan, tilt, and zoom and more functionality over IP. It is recommended to use top0 Camera Tool for Windows to achieve more advanced control.",
      },
      {
        ques: "Can this ptz camera output video at 60 frames per second?",
        answ:
          "Yes. This camera can output full HD video resolutions of 1920×1080 video with frame rates up to 60 FPS.",
      },
      {
        ques: "Does this ptz camera include an ir remote control?",
        answ:
          "Yes. All top0 PTZ cameras include an IR remote control. Each can be set to operate up to four cameras. It is good for fast control of the basic functions of the camera, including but not limited to: Pan and Tilt; Lens zoom and focus; Image flip, mirror and freeze; Setting and calling of preset positions. What's more, you can use the on-screen display menu to fine-tune your camera settings. Of course, you can also use the top0 Camera Tool to complete the operation.",
      },
      {
        ques: "Does this ptz camera support broadcast frame rates?",
        answ:
          "No,it doesn’t.If you need broadcast frame rate support, we recommend buying top0 T7E, T7S, T7P or T7F series cameras.",
      },
      {
        ques:
          "Can this ptz camera live-stream directly to facebook or youtube?",
        answ:
          "Yes. This camera can be set up to live-stream directly to almost any RTMP server-based CDN (Content Delivery Network). To add audio to your cameras RTMP or RTMPS stream,you should use a powered line-level audio source connected to the 3.5mm audio input on the camera(Not a mic-in).",
      },
      {
        ques: "Is there software that can be used to control the ptz camera?",
        answ:
          "Yes, top0 Camera Tool for Windows has been released. And it is fully compatible with cameras of other brands such as PTZOptics. And please look forward to the release of top0 Camera Tool for macOS, Android and IOS systems soon. At that time, you can use your mobile phone to preview and control the PTZ camera.",
      },
      {
        ques: "Can you use a smartphone app to control these ptz cameras?",
        answ:
          "Not yet, but top0 Camera Tool (free) for Android and IOS is in development and it will be released soon. At that time, you can use your mobile phone to preview and control the PTZ camera.For now, you can use applications from other manufacturers, such as PanTiltZoom from PTZOptics. Our cameras are fully compatible with applications from PTZOptics.",
      },
      {
        ques: "Can this camera support input/output audio?",
        answ:
          "There is line-level audio into this camera that can be accessed via HDMI, USB, and IP streaming.",
      },
      {
        ques: "Can you control this camera with vmix?",
        answ:
          "Yes. You can control this camera with the 4K or professional versions of vMix. vMix can control the camera’s pan, tilt and zoom functionalities along with recalling camera presets. You must have successfully configured the IP address of the camera before you can control it with vMix.",
      },
      {
        ques: "Can you leave ptz cameras on for an extended period?",
        answ: "Support long working hours (24/7 mode).",
      },
      {
        ques: "Can this camera be used in harsh outdoor weather conditions?",
        answ:
          "Not recommended. This camera is not weatherproof/waterproof. The operating temperature range is -10°C ~ 40°C (14°F ~ 104°F), storage temperature range is -40°C ~ 60°C (-40°F ~ 140°F).",
      },
    ],
  }),
  mounted() {},
  methods: {
    download() {
      window.open(
        "https://api.top0.com/api/Download/top0CameraTool?platform=windows&version=1.0.0"
      );
    },
    gotoam() {
      window.open("https://www.amazon.com/dp/B09J4RGB8F");
    },
    downloadUM() {
      window.open(
        "https://api.top0.com/api/Download/getUserManual?productModel=RC26"
      );
    },
    downloadDS() {
      window.open(
        "https://api.top0.com/api/Download/getDatasheet?productModel=RC26"
      );
    },
  },
};
</script>
<style scoped>
.Scrollactive {
  padding-top: 64px;
}
.disActive {
  padding-top: 0;
}
</style>
