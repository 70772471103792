<template>
  <div>
    <div
      style="width:100%;background-color:#fff;position:fixed;z-index:2;padding-top: 64px"
    >
      <v-row class="pa-4 align-center d-flex justify-center">
        <v-col class="col-md-2 text-center">
          <h2>T7F-NDI</h2>
        </v-col>
        <v-col
          class="col-md-8 text-center"
          v-show="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn text @click="$vuetify.goTo(0, options)">
            OverView
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#how', options)">
            Tech Specs
          </v-btn>

          <v-btn text @click="$vuetify.goTo('#faq', options)">
            FAQ
          </v-btn>
          <v-btn text @click="gotoDownload()">
            Downloads<v-icon right dark>
              mdi-download
            </v-icon>
          </v-btn>
        </v-col>
        <v-col class="col-md-2 text-center">
          <v-btn
            elevation="0"
            large
            rounded
            color="success"
            @click="$vuetify.goTo('#buy', options)"
          >
            Buy Now<v-icon right>
              mdi-cart
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-show="$vuetify.breakpoint.smAndDown">
        <v-col class="mb-2 text-center" cols="12">
          <v-btn text @click="$vuetify.goTo(0, options)" small>
            OverView
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#how', options)" small>
            Tech Specs
          </v-btn>
          <v-btn text @click="$vuetify.goTo('#faq', options)" small>
            FAQ
          </v-btn>
          <v-btn text @click="gotoDownload()" small>
            Downloads
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
    </div>
    <div style="padding-top:250px;padding-bottom:250px">
      <v-row>
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">OverView</h1></v-col
        >
      </v-row>
      <!-- <v-row>
        <v-col class="col-md-12 pa-10">
          <h3 class="text-left">DESIGNED FOR SERIOUS STREAMERS</h3>
          <p>
            Connect with superior clarity every time you go live on channels
            like Twitch and YouTube. Stream anything you want in your choice of
            Full 1080p at 30fps or hyperfast HD 720p at 60fps. Broadcast
            masterfully with reliable no-drop audio, autofocus, and a 78°
            diagonal field of view. Includes free 3-month premium XSplit
            license. C922 streaming webcam
          </p></v-col
        >
      </v-row> -->
      <v-row>
        <!-- <v-col class="col-md-8 pa-10" cols="12">
          <h3 class="text-left">FULL HIGH-DEF 1080P STREAMING</h3>
          <p>
            Stream and record vibrant, true-to-life video. C922 features a glass
            lens with autofocus and a 78° diagonal field of view. Full HD
            streaming captures all the details, bright, natural colors, and
            fluid video at 1080p/30fps—and in HD at 720p/60fps. Use Capture to
            zoom, pan, and edit. Full High-def streaming Hpyerfast frame rate
            streaming webcamStream and record vibrant, true-to-life video. C922
            features a glass lens with autofocus and a 78° diagonal field of
            view. Full HD streaming captures all the details, bright, natural
            colors, and fluid video at 1080p/30fps—and in HD at 720p/60fps. Use
            Capture to zoom, pan, and edit. Full High-def streaming Hpyerfast
            frame rate streaming webcamStream and record vibrant, true-to-life
            video. C922 features a glass lens with autofocus and a 78° diagonal
            field of view. Full HD streaming captures all the details, bright,
            natural colors, and fluid video at 1080p/30fps—and in HD at
            720p/60fps. Use Capture to zoom, pan, and edit. Full High-def
            streaming Hpyerfast frame rate streaming webcam
          </p>
        </v-col> -->
        <v-col class="col-md-12 pa-10" cols="12">
          <img src="../../img/T7P-SDI.png" style="width:100%"
        /></v-col>
      </v-row>

      <v-row id="how">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            Tech Specs
          </h1></v-col
        >
      </v-row>
      <v-row>
        <v-col class="col-md-12 d-flex justify-center">
          <img src="../../img/T7P-SDI.png" style="width:200px" />
        </v-col>
      </v-row>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"><h2>Basic</h2></v-col>
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>Model</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                T7F-NDI-12X / T7F-NDI-20X / T7F-NDI-30X
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Lens Zoom</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">12X / 20X / 30X</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Color</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Space Gray / White <br /></p
            ></v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Video Outputs</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>Video Outputs</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1:NDI|HX® (1080p@60fps capable)*1
              </p>
              <p class="font-weight-light">
                2:3G-SDI (up to 1080P@60fps capable)
              </p>
              <p class="font-weight-light">
                3:HDMI (up to 1080p@60fps capable)
              </p>
              <p class="font-weight-light">4:CVBS*2</p>
              <p class="font-weight-light">
                5:Video over Ethernet (1080p@60fps capable)*3
              </p>
              <p class="font-weight-light">
                (*1: IP first stream must be set to H.264 decoding)
              </p>
              <p class="font-weight-light">
                (*2:CVBS Not simultaneous with SDI or HDMI outputs; SD only
                (576i-30, 480i-30))
              </p>
              <p class="font-weight-light">
                (*2:H.264, H.265, MJPEG Encoding; RTSP; RTMP; NDI|HX®support)
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Connectors</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1:3G-SDI (BNC – 75 Ohm, Female)
              </p>
              <p class="font-weight-light">2:HDMI v1.4 Type A (Female)</p>
              <p class="font-weight-light">3:10/100/1000 RJ-45</p>
              <p class="font-weight-light">
                4:Non-powered 3.5mm Female Input(Line-in)
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Aspect Ratios</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                4:3, 16:9
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Standard Resolutions</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                PAL, PAL wide, NTSC, NTSC Wide
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Frame Rate</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Up to 60 fps
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>
                Recommended maximum Cable Length for SDI (based on standards)
              </h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                300 ft
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>
                Recommended maximum Cable Length for HDMI (based on standards)
              </h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                45 ft
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>
                Recommended maximum Cable Length for Ethernet (based on
                standards)
              </h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                328 ft
              </p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Camera and Lens</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>Video Sensor</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Panasonic 1/2.7" CMOS, 2.07 Megapixels
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Scanning Mode</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Progressive</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Focal Length</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12x Optical, f=3.5mm-42.3mm, F1.8-F2.8
              </p>
              <p class="font-weight-light">
                20x Optical, f=4.42mm-88.5mm, F1.8-F2.8
              </p>
              <p class="font-weight-light">
                30x Optical, f=4.42mm-132.6mm, F1.8-F2.8
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Lens Zoom</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12X Optical , 20X Optical or 30X Optical
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Digital Zoom</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">16x / 8x</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Field of View</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                72.5°(12x) or 60.7°(20x/30x)
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Frame Rates</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Full Frame Rates: 1080p-60/50/30/25, 1080-60/50,
                720p-60/50/30/25
              </p>
              <p class="font-weight-light">
                Experimental Broadcast Frame Rates: 1080p59.94, 1080i59.94,
                1080p29.97, 720p59.94 (Beta)
              </p>
              <p class="font-weight-light">
                CVBS Only (576i-30, 480i-30)
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Min Lux</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                0.5 Lux @ (F1.8, AGC ON)
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Shutter Speed</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1/30s ~ 1/10000s
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>SNR</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                ≥55dB
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Digital Noise Reduction</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                2D&3D Digital Noise Reduction
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Wide Dynamic Range</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>White Balance</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Auto, Indoor, Outdoor, One Push, Manual, VAR
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Horizontal Angle of View</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12X Lens – 6.9° (tele) to 72.5° (wide)
              </p>
              <p class="font-weight-light">
                20X Lens – 3.36° (tele) to 60.7° (wide)
              </p>
              <p class="font-weight-light">
                30X Lens – 2.28° (tele) to 60.7° (wide)
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Vertical Angle of View</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12X Lens – 3.9° (tele) to 44.8° (wide)
              </p>
              <p class="font-weight-light">
                20X Lens – 1.89° (tele) to 34.1° (wide)
              </p>
              <p class="font-weight-light">
                30X Lens – 1.28° (tele) to 34.1° (wide)
              </p>
            </v-col> </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Vertical Flip & Mirror</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>HDMI Image Freeze</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Backlight Compensation</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Pan & Tilt Movement</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>Pan Movement</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                ±170°
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Tilt Rotation</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Up: 90°, Down: 30°</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Pan Speed Range</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1.7° ~ 100°/s
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Tilt Speed Range</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1.7° ~ 69.9°/s
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Presets</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                10 via IR (255 via Serial or IP)
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Preset Accuracy</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">0.1°</p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Networking</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>DHCP</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Static IP</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Support</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Multicast</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Dual Streaming</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>720p120fps</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Support
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>HTTP Port Number</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Default 80</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>RTSP Port</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Default 554</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>PTZ Port</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Default 5678</p></v-col
            >
          </v-row>

          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>UDP Port</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Default 1259</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Web Management</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Support</p>
              <p class="font-weight-light">Guest & Admin Logins</p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"><h2>Audio</h2></v-col>
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "
              ><h4>Analog audio inputs</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Line In, 3.5mm (Not Mic-in, HDMI & NDI & IP StreamSupport)
              </p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>IPC Features</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "
              ><h4>Video Compression</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                H.265/H.264/MJEPG
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Video Stream</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">Main Stream, Sub Stream</p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Main Stream Resolution</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1920x1080,1280x720,1024x576,960x540,640x480,640x360
              </p>
            </v-col>
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Sub Stream Resolution</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                1280x720,1024x576,720x576,
                720x480,720x408,640x360,480x270,320x240,320x180
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Video Bit Rate</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                32Kbps ~ 102400Kbps
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Bit Rate Type</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Variable Rate / Fixed Rate
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Frame Rate</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                50Hz: 1fps ~ 50fps, 60Hz: 1fps ~ 60fps
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Audio Compression</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">AAC</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Audio Bit Rate</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">96Kbps, 128Kbps, 256Kbps</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Support protocols</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                NDI, TCP/IP, HTTP, RTSP, RTMP, Onvif, DHCP, Multicast, etc.
              </p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-10">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Camera Control</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "
              ><h4>8-pin mini din In</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                RS-232, RS-485, VISCA, PELCO-D/P
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>8-pin mini din Out (passthrough)</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Used to cascade RS-232, RS-485, VISCA, PELCO-D/P controls to
                next camera in line
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>RS-485</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                2-pin phoenix port
              </p>
              <p class="font-weight-light">
                Protocols: VISCA/Pelco-D/Pelco-P
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Baud Rate</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                2400/4800/9600 bits
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>IP</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                NDI PTZ, VISCA over IP, Onvif
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Recommended maximum cable length for RS-232</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                90 feet (30 meters)
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Recommended maximum cable length for RS-485</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">3,600 feet (1,200 meters)</p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Recommended maximum cable length for RS-485</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">96Kbps, 128Kbps, 256Kbps</p></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="pa-4">
        <v-col class="col-md-2 text-center" cols="12"
          ><h2>Electrical Index</h2></v-col
        >
        <v-col class="col-md-10">
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left "><h4>Power Supply</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12W (Max)
              </p></v-col
            >
          </v-row>
          <v-row class="clearfix">
            <v-col class="col-md-6 text-left"><h4>Input Voltage</h4></v-col>
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                12V DC (10.8 – 13.0V DC) or PoE 802.3af
              </p></v-col
            > </v-row
          ><v-row class="clearfix">
            <v-col class="col-md-6 text-left"
              ><h4>Power over Ethernet</h4></v-col
            >
            <v-col class="col-md-6 text-left"
              ><p class="font-weight-light">
                Standard PoE supported
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row id="down">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            Downloads
          </h1></v-col
        >
      </v-row>
      <v-row class="pa-4 justify-center">
        <v-col class="col-md-4" cols="12">
          <v-card outlined>
            <v-card-title>
              top0 Camera Tool
            </v-card-title>
            <v-card-subtitle>
              Advanced top0 camera controls. With pan, tilt, lens, image, color,
              power, OSD, live video preview, panoramic preset, IP
              configuration, firmware upgrade and more support.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="orange lighten-2" text @click="show = !show"
                >download
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </v-btn>
            </v-card-actions>

            <v-expand-transition>
              <div v-show="show">
                <v-divider></v-divider>
                <v-row>
                  <v-col class="col-md-5"
                    ><v-card-subtitle class="font-weight-bold">
                      Why Update?
                    </v-card-subtitle>
                    <v-card-text>
                      Fully integrated configuration & control; new support for
                      Xbox and Playstation gamepad control; new support for
                      advanced functions of PTZoptics cameras (Motion Sync,
                      Pan/Tilt Limits, Focus Lock); Some bug fixes.
                    </v-card-text></v-col
                  >
                  <v-col class="col-md-7" style="padding-top:40px">
                    <h4>SoftVersion：7.01.002</h4>
                    <h4>LastUpdate：2021-11-20</h4>
                    <h4>
                      OS：
                    </h4>
                    <v-select
                      flat
                      disabled
                      label="windows"
                      solo
                      dense
                      outlined
                      style="width:80%"
                    ></v-select>
                    <v-btn
                      class="ma-2"
                      outlined
                      fab
                      color="success"
                      @click="download()"
                    >
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                    <p class="font-italic subtitle-2" style="color:red">
                      <span>More platform versions will Coming Soon!!</span>
                      Before Release, we recommend using the application of
                      PTZOptics. top0 camera is fully compatible with PTZoptics
                      application.
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
        </v-col>
        <v-col class="col-md-4" cols="12">
          <v-card class="ma-1" outlined>
            <v-card-title>
              Document
            </v-card-title>
            <v-card-subtitle>
              A compilation of product data sheets and manuals
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="downloadUM()"
                >User Manual<v-icon>mdi-download</v-icon>
              </v-btn>
              <v-btn color="success" text @click="downloadDS()"
                >DataSheet<v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-md-4" cols="12">
          <v-card class="ma-1" outlined>
            <v-card-title>
              OBS DOCK
            </v-card-title>
            <v-card-subtitle>
              (Coming soon!)Simple top0 camera controls for OBS Dock. With pan,
              tilt, lens, image, color, power, OSD, panoramic preset and more
              support.
            </v-card-subtitle>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="success" text @click="show = !show"
                >download<v-icon>mdi-download</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row class="pa-10 " style="background-color:#212121" id="buy">
        <v-col class="col-md-8" cols="12">
          <img src="../../img/T7P-SDI.png" style="width:100%" />
        </v-col>
        <v-col class="col-md-4" cols="12">
          <v-card class="mx-auto" elevation="0" color="transparent" dark>
            <v-card-text>
              <div style="padding-bottom:50px">
                <p class="text-h2 font-weight-bold" style="color:#fff">
                  top0 T7F-NDI
                </p>
                <p>
                  1080P@60fps NDI PTZ camera Gen2 with 3G-SDI, HDMI and IP video output
                </p>
              </div>
              <!-- <p class="text-h4" style="color:#fff">
                <v-icon color="amber darken-1" style="padding-right:20px"
                  >mdi-sale</v-icon
                >$749/$859
              </p> -->
              <p class="font-italic">Available with 12/20/30x optical zoom</p>
            </v-card-text>
            <v-divider dark></v-divider>
            <v-card-actions>
              <v-btn
                color="success"
                rounded
                style="margin:50px auto"
                @click="gotoam()"
              >
                Buy From Amazon.com
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="pa-10" id="faq">
        <v-col class="col-md-12">
          <h1 class="text-center" style="color:#1E88E5">
            FAQ - top0 T7F-NDI
          </h1></v-col
        >
      </v-row>
      <v-row class="pa-10">
        <v-col class="col-md-12 mx-auto text-center" cols="12">
          <v-expansion-panels>
            <v-expansion-panel v-for="item in faqitems" :key="item.ques">
              <v-expansion-panel-header style="color:#1565C0">
                {{ item.ques }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ item.answ }}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    isActive: true,
    show: false,
    options: {
      offset: 240,
    },
    osItems: ["WINDOWS"],
    faqitems: [
      {
        ques: "What is the difference between T7F and other HD cameras?",
        answ:
          "T7F is Gen2. It has more features, such as the ability to set the focal length range and Pan-Tilt range. Higher bit rate, SRT dual mode support and save settings for each preset and more.",
      },
      {
        ques: "Does it include an NDI license?",
        answ:
          "Yes.",
      },
      {
        ques: "In what configuration does NDI work perfectly?",
        answ:
          "Network First Stream must be set to H.264 encoding. 1080P@60fps is recommended for a better "},
      {
        ques: "Can I Use 3G-SDI, HDMI and IP Video Output at the Same Time?",
        answ:
          "Yes, and they can output video up to 1080p@60fps at the same time.",
      },
      {
        ques: "Does this ptz camera support poe?",
        answ: "Yes. This camera supports Power over Ethernet (802.3af)",
      },
      {
        ques: "Can these ptz cameras be controlled over the network?",
        answ:
          "Yes, after you have set up the camera network you can control it using VISCA over IP/ONVIF/HTTP-CGI/WebUI etc. Here it is recommended to work with top0 Camera Tool.",
      },
      {
        ques: "Can this ptz camera output video at 60 frames per second?",
        answ:
          "Yes. This camera can output full HD video resolutions of 1920×1080 video with frame rates up to 60 FPS. And it support 720p@120FPS video output mode.",
      },
      {
        ques: "Does the usb interface of this camera support video output?",
        answ:
          "No. The USB port of this camera can only connect to FAT32 format storage devices for video recording. And this function needs to work with top0 Camera Tool.",
      },
      {
        ques: "Does this ptz camera include an ir remote control?",
        answ:
          "Yes. All top0 PTZ cameras include an IR remote control. Each can be set to operate up to four cameras. It is good for fast control of the basic functions of the camera, including but not limited to: Pan and Tilt; Lens zoom and focus; Image flip, mirror and freeze; Setting and calling of preset positions. What’s more, you can use the on-screen display menu to fine-tune your camera settings. Of course, you can also use the top0 Camera Tool to complete the operation.",
      },
      {
        ques: "Does this ptz camera support broadcast frame rates?",
        answ:
          "Yes. This camera model can output video with broadcast frame rates. Broadcast frame rates include 59.94 fps and 29.97 fps.",
      },
      {
        ques:
          "Can this ptz camera live-stream directly to facebook or youtube?",
        answ:
          "Yes. This camera can be set up to live-stream directly to almost any RTMP server-based CDN (Content Delivery Network). To add audio to your cameras RTMP or RTMPS stream,you should use a powered line-level audio source connected to the 3.5mm audio input on the camera(Not a mic-in).",
      },
      {
        ques: "Is there software that can be used to control the ptz camera?",
        answ:
          "Yes, top0 Camera Tool for Windows has been released. And it is fully compatible with cameras of other brands such as PTZOptics. And please look forward to the release of top0 Camera Tool for macOS, Android and IOS systems soon. At that time, you can use your mobile phone to preview and control the PTZ camera.",
      },
      {
        ques: "Can you use a smartphone app to control these ptz cameras?",
        answ:
          "Not yet, but top0 Camera Tool (free) for Android and IOS is in development and it will be released soon. At that time, you can use your mobile phone to preview and control the PTZ camera.For now, you can use applications from other manufacturers, such as PanTiltZoom from PTZOptics. Our cameras are fully compatible with applications from PTZOptics.",
      },
      {
        ques: "Can this camera support input/output audio?",
        answ:
          "There is line-level audio into this camera that can be accessed via HDMI and IP streaming.",
      },
      {
        ques:
          "Can I save settings such as exposure, color, image and recall position speed for each preset?",
        answ:
          "Yes. When you set a preset, the camera will automatically save the current exposure, color, image and recall position speed configuration to this index. We recommend using top0 Camera Tool for advanced preset settings.",
      },
      {
        ques: "Can you leave ptz cameras on for an extended period?",
        answ: "Support long working hours (24/7 mode).",
      },
      {
        ques: "Can you control this camera with vmix?",
        answ:
          "Yes. You can control this camera with the 4K or professional versions of vMix. vMix can control the camera’s pan, tilt and zoom functionalities along with recalling camera presets. You must have successfully configured the IP address of the camera before you can control it with vMix.",
      },
      {
        ques:
          "Can I use it as a USB camera to work with software like ZOOM?",
        answ:
          "Yes. But it needs to be used as a virtual USB camera. For example, use SplitCam to use an NDI stream as input for a low latency experience. Or use an HDMI/3G-SDI capture card. You can find the solution in the knowledge base or in the user manual.",
      },
      {
        ques: "Can this camera be used in harsh outdoor weather conditions?",
        answ:
          "Not recommended. This camera is not weatherproof/waterproof. The operating temperature range is -10°C ~ 40°C (14°F ~ 104°F), storage temperature range is -40°C ~ 60°C (-40°F ~ 140°F).",
      },
    ],
  }),
  mounted() {},
  methods: {
    download() {
      window.open(
        "https://api.top0.com/api/Download/top0CameraTool?platform=windows&version=1.0.0"
      );
    },
    gotoam() {
      window.open("https://www.amazon.com/dp/B0BGHLP5WT");
    },
    downloadUM() {
      window.open(
        "https://api.top0.com/api/Download/getUserManual?productModel=T7F-NDI"
      );
    },
    downloadDS() {
      window.open(
        "https://api.top0.com/api/Download/getDatasheet?productModel=T7F-NDI"
      );
    },
    gotoDownload() {
      this.$router.push({
        path: "/Download",
      });
    },
  },
};
</script>
<style scoped>
.Scrollactive {
  padding-top: 64px;
}
.disActive {
  padding-top: 0;
}
</style>
