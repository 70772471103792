<template>
  <div>
    <v-row>
      <div
        class="swiper-container"
        id="swiperTop"
        v-show="$vuetify.breakpoint.smAndDown"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1_Mobile.jpg" />
          </div>
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1_Mobile.jpg" />
          </div>

          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1_Mobile.jpg" />
          </div>
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1_Mobile.jpg" />
          </div>
        </div>

        <div class="swiper-pagination"></div>
      </div>
      <div
        class="swiper-container"
        id="swiperTop2"
        v-show="$vuetify.breakpoint.mdAndUp"
      >
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1.jpg" />
          </div>
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1.jpg" />
          </div>
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1.jpg" />
          </div>
          <div class="swiper-slide">
            <img style="width:100%" src="../../img/1.jpg" />
          </div>
        </div>

        <div class="swiper-pagination"></div>
      </div>
    </v-row>
    <v-row class="pa-10 align-center d-flex justify-center">
      <v-col class="col-md-2 align-center d-flex justify-center"
        ><h1 class="text-h4 font-weight-bold">Integrations</h1></v-col
      >
      <v-col class="col-md-10">
        <div class="swiper-container" id="swiperIntegrations">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <v-card class="ma-3">
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/obs.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      obsproject.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      OBS Studio
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >OBS Studio is a free and open-source cross-platform
                      streaming and recording program built with Qt and
                      maintained by the OBS Project.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3">
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/vmix.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.vmix.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      vMix
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >vMix is a software vision mixer available for the Windows
                      operating system. The software is developed by StudioCoast
                      PTY LTD.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3">
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/zoom.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      zoom.us
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Zoom Meetings
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Zoom Meetings is a proprietary video teleconferencing
                      software program developed by Zoom Video
                      Communications.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/wirecast.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.telestream.net
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Wirecast
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Wirecast is a live video streaming production tool by
                      Telestream. It allows users to create live or on-demand
                      broadcasts for the web.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/livestream.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      livestream.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Livestream
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Vimeo Livestream is a video live streaming platform based
                      in New York City.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/twitch.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.twitch.tv
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Twitch
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Twitch is an American video live streaming service that
                      focuses on video game live streaming, including broadcasts
                      of esports competitions.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/blackmagicdesign.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.blackmagicdesign.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Blackmagic Design
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Blackmagic Design is an Australian digital cinema company
                      and manufacturer based in Port Melbourne, Victoria,
                      Australia.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/newtek.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.newtek.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      NewTek
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >NewTek, Inc. is a San Antonio, Texas–based hardware and
                      software company.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/crestron.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.crestron.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Crestron
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Crestron Electronics is an American privately held
                      multinational corporation, based in Rockleigh, New Jersey.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="ma-3" light>
                <v-list-item class="pa-5 align-center d-flex justify-center">
                  <img src="../../img/extron.png" width="100%" />
                </v-list-item>
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      www.extron.com
                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      Extron
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >Extron Electronics, is a manufacturer of professional
                      audiovisual equipment. It is headquartered in Anaheim,
                      California.
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-card-actions>
                  <v-btn text color="blue darken-1">
                    More
                  </v-btn>
                </v-card-actions>
              </v-card>
            </div>
          </div>
        </div>
      </v-col>
      <v-btn text color="blue darken-1" dark class="ma-10"
        >View all of our integration partners<v-icon right
          >mdi-dots-horizontal-circle-outline</v-icon
        ></v-btn
      >
    </v-row>
    <v-row
      class="pa-4 align-start d-flex justify-center"
      style="background-color:#e9e9e9"
    >
      <v-col
        class="col-lg-4 col-md-6 align-start flex-column justify-start"
        cols="12"
        ><p class="text-h4 font-weight-bold">
          PTZ Camera <span style="color:#1E88E5">Basics</span>
        </p>
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              Simultaneous Video Outputs
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              top0 PTZ Camera of T7E, T7S, T7P series, can send three
              simultaneous video streams through 3G-SDI, HDMI, USB 3.0 and IP
              streaming depending on the model you selected. Accommodate
              multiple video production workflows and achieve as many video
              project goals as possible!
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              Various camera control options
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              In general, IP control is the preferred standard for ease of use
              of top0 PTZ cameras. Although top0 Camera Tool (Windows version)
              supports IP, serial or USB (USB models only) to connect and
              control the camera. Using this tool, you can even connect the Xbox
              or PlayStation game controller to control the camera. Similarly,
              controlling the camera via RS-232 or RS-485 is used for many
              classic control systems and PTZ joysticks.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              IP Streaming
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              All top0 PTZ cameras support IP video and audio streaming
              capabilities. Once the camera IP configuration is completed and
              successfully connected, use top0 Camera Tool or log into the
              Web-GUI with a web browser to setup the RTMP or RTSP streaming
              settings. At the same time, Line-in audio can be embedded via the
              3.5 mm audio input.(Not Mic-in signal, only 4k models support
              mic-in). So, live stream can easily and directly content delivery
              networks like Facebook or YouTube.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              IR Remote Control
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              All top0 PTZ cameras include an IR remote control. Each can be set
              to operate up to four cameras. It is good for fast control of the
              basic functions of the camera, including but not limited to: Pan
              and Tilt; Lens zoom and focus; Image flip, mirror and freeze;
              Setting and calling of preset positions. What’s more, you can use
              the on-screen display menu to fine-tune your camera settings. Of
              course, you can also use the top0 Camera Tool to complete the
              operation.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              Cabling Options and Limitations
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              top0 PTZ cameras offer various video outputs including 3G-SDI,
              HDMI, USB 3.0, IP Streaming, and CVBS. Each video cable has its
              own distance limitations. 3G-SDI cabling is preferred for long
              distances up to 1,000’. Network cabling for IP streaming can be
              extended up to 328’. HDMI cabling can reach distances up to 50’
              without requiring extensions. USB3.0 cabling distances should be
              limited to 9’. CVBS cabling limitations are highly dependent on
              the quality of cabling.
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header style="color:#1565C0">
              2-Year Warranty
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              Every top0 PTZ camera comes with a 2-year manufacturer’s warranty
              that covers any and all manufacturing defects within the first 2
              years following purchase. This warranty does not cover damage
              caused by actual end user damage or modification caused by using
              third-party tools to upgrade the faulty firmware or the SDK
              (software development kit).
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col class="col-lg-8 col-md-6 pa-0">
        <!-- <p class="text-h4 font-weight-bold">Future Proof Your Technology</p> -->
        <v-parallax src="../../img/top0PTZCamera.jpg"></v-parallax>
      </v-col>
    </v-row>
    <v-row
      class="pa-10 align-center d-flex justify-center"
      style="background-color:#37474F"
    >
      <v-col
        class="col-md-12 align-center d-flex justify-center flex-column ma-5"
        ><h1 class="text-h4 font-weight-bold" style="color:#fff">
          Camera Solutions
          <span class="text-h5 font-weight-bold" style="color:#1E88E5"
            >For All Your Needs</span
          >
        </h1>
        <v-btn text color="blue-grey lighten-2" dark class="ma-10"
          >More solutions<v-icon right
            >mdi-dots-horizontal-circle-outline</v-icon
          ></v-btn
        >
      </v-col>
      <v-col>
        <div class="swiper-container" id="swiperSol">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img
                  height="300"
                  src="../../img/business-5840870.png"
                ></v-img>
                <v-card-title class="justify-center">Business</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img height="300" src="../../img/church-2015000.png"></v-img>
                <v-card-title class="justify-center">Church</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img height="300" src="../../img/doctor-5840874.png"></v-img>
                <v-card-title class="justify-center">Doctor</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img
                  height="300"
                  src="../../img/education-5600987.png"
                ></v-img>
                <v-card-title class="justify-center">Education</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img height="300" src="../../img/news-5559927.jpg"></v-img>
                <v-card-title class="justify-center">News</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img
                  height="300"
                  src="../../img/olympics-2943519.png"
                ></v-img>
                <v-card-title class="justify-center">Olympics</v-card-title>
              </v-card>
            </div>
            <div class="swiper-slide">
              <v-card class="mx-auto">
                <v-img
                  height="300"
                  src="../../img/public-speaking-3159217.jpg"
                ></v-img>
                <v-card-title class="justify-center"
                  >Public Speaking</v-card-title
                >
              </v-card>
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </v-col>
    </v-row>
    <v-row class="align-center pt-16 pb-16 d-flex justify-center"
      ><v-col
        class="col-md-3 pt-10 align-end  flex-column  justify-end"
        cols="12"
      >
        <p class="text-h4 text-center font-weight-bold">
          Camera Applications
        </p>
        <p class="text-h5 text-center font-weight-bold" style="color:#1E88E5">
          For Advanced Controls
        </p></v-col
      ><v-col class="col-md-9 pt-10">
        <v-sheet>
          <v-slide-group v-model="model" class="pa-4" show-arrows>
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                @click="toggle"
                height="240"
                width="240"
              >
                <img src="../../img/ForWindows.png" style="width:100%" />
              </v-card>
            </v-slide-item>
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="240"
                width="240"
                @click="toggle"
              >
                <img src="../../img/ForMacOS.png" style="width:100%" />
              </v-card>
            </v-slide-item>
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="240"
                width="240"
                @click="toggle"
              >
                <img src="../../img/ForAndroid.png" style="width:100%" />
              </v-card>
            </v-slide-item>
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="240"
                width="240"
                @click="toggle"
              >
                <img src="../../img/ForIOS.png" style="width:100%" />
              </v-card>
            </v-slide-item>
            <v-slide-item v-slot="{ active, toggle }">
              <v-card
                :color="active ? 'primary' : 'grey lighten-1'"
                class="ma-4"
                height="240"
                width="240"
                @click="toggle"
              >
                <img src="../../img/ForOBS.png" style="width:100%" />
              </v-card>
            </v-slide-item>
          </v-slide-group>

          <v-expand-transition>
            <v-sheet v-if="model != null" height="200" tile>
              <v-row
                class="fill-height pa-10"
                align="center"
                justify="center"
                cols="12"
              >
                <h3
                  class="text-h7 text-center font-italic"
                  style="color:#424242"
                >
                  {{ controls[model].des }}
                </h3>
              </v-row>
            </v-sheet>
          </v-expand-transition>
        </v-sheet>
      </v-col></v-row
    >
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  name: "FirstPage",
  props: {
    msg: String,
  },
  data() {
    return {
      sidebar: false,
      model: null,
      controls: [
        {
          des:
            "Advanced top0 camera controls for Windows. With pan, tilt, lens, image, color, power, OSD, live video preview, panoramic preset, IP configuration, firmware upgrade and more support.",
        },
        {
          des:
            "(Coming soon!)Advanced top0 camera controls for Mac OS. With pan, tilt, lens, image, color, power, OSD, live video preview, panoramic preset, IP configuration, firmware upgrade and more support.",
        },
        {
          des:
            "(Coming soon!)Advanced top0 camera controls for Android. With pan, tilt, lens, image, color, power, OSD, live video preview, panoramic preset, IP configuration and more support.",
        },
        {
          des:
            "(Coming soon!)Advanced top0 camera controls for IOS. With pan, tilt, lens, image, color, power, OSD, live video preview, panoramic preset, IP configuration and more support.",
        },
        {
          des:
            "(Coming soon!)Simple top0 camera controls for OBS Dock. With pan, tilt, lens, image, color, power, OSD, panoramic preset and more support.",
        },
        {
          des:
            "Advanced PTZ camera controls, hotkeys support, and much more available inside of OBS.",
        },
      ],
    };
  },
  mounted() {
    var that = this;
    var swiperTop = new Swiper("#swiperTop", {
      loop: true,
      pagination: ".swiper-pagination",
      paginationClickable: true,
      autoplay: 2000,
    });
    var swiperTop2 = new Swiper("#swiperTop2", {
      loop: true,
      pagination: ".swiper-pagination",
      paginationClickable: true,
      autoplay: 2000,
    });
    var swiperIntegrations = new Swiper("#swiperIntegrations", {
      slidesPerView: that.changeView(),
      autoplay: 1000,
      loop: true,
    });
    var swiperSol = new Swiper("#swiperSol", {
      autoplay: 1000,
      effect: "coverflow",
      slidesPerView: that.changeView(),
      centeredSlides: true,
      initialSlide: 3,
      coverflow: {
        rotate: 20,
        stretch: 0,
        depth: 200,
        modifier: 1,
        slideShadows: true,
      },
    });
  },
  methods: {
    changeView() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return 2;
        case "md":
          return 3;
        case "lg":
          return 4;
        case "xl":
          return 5;
      }
    },
  },
};
</script>
<style scoped>
#swiperTop {
  width: 100%;
}
#swiperTop .swiper-wrapper .swiper-slide {
  width: 100%;
  text-align: center;
}
</style>
