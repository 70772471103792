<template>
  <div
    style="padding-top: 64px;padding-bottom:200px;"
    class="d-flex justify-center"
  >
    <div class="col-md-6 pt-10" cols="12">
      <h1>{{ content.articletitle }}</h1>
      <p class="pa-2" style="color:#78909C">
        Realese Date : {{ content.updatetime }}
      </p>
      <p v-html="content.articlecontent"></p>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      content: "",
    };
  },
  components: {},
  mounted() {
    var that = this;
    var artId = that.$route.params.id;
    that.getArtcontent(artId);
  },
  methods: {
    getArtcontent(artId) {
      var that = this;
      axios
        .get("api/Knowledge/GetArticleInfo?articleid=" + artId)
        .then(function(response) {
          if (response.data.code == 1) {
            console.log(response.data);
            that.content = response.data.data;
          } else {
            that.typeList = response.data.msg;
          }
        });
    },
  },
};
</script>
