<template>
  <v-app>
    <v-app-bar
      :color="barColor"
      light
      v-show="$vuetify.breakpoint.smAndDown"
      fixed
      hide-on-scroll
      flat
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        dark
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-img
        @click="gohome()"
        max-width="100"
        src="../img/logo_WH_750.png"
      ></v-img>
      <v-spacer></v-spacer>

      <v-btn icon @click="gotoContact()">
        <v-icon style="color:#fff">mdi-forum</v-icon>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      v-show="$vuetify.breakpoint.smAndDown"
      fixed
      style="z-index:9999"
    >
      <v-list-item class="pa-10" style="background-color:#1E88E5">
        <v-list-item-content>
          <v-img
            @click="gohome()"
            width="100%"
            src="../img/logo_WH_750.png"
          ></v-img>
          <v-list-item-subtitle
            class="text-center font-weight-bold"
            style="color:#01579B"
          >
            www.top0.com
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list>
        <v-list-item @click="gohome()">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Home</v-list-item-title>
        </v-list-item>
        <v-list-group :value="false" v-for="item in items" :key="item.title">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon>mdi-camera</v-icon>
            </v-list-item-icon>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </template>

          <v-list-group
            :value="false"
            no-action
            sub-group
            v-for="child in item.items"
            :key="child.title"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item v-for="childs in child.items" :key="childs.title" link>
              <v-list-item-icon>
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="childs.icon"></use>
                </svg>
                <!-- <i :class="childs.icon"></i> -->
                <!-- <v-icon>{{ childs.icon }}</v-icon> -->
              </v-list-item-icon>
              <v-list-item-title
                v-text="childs.title"
                @click="gotoDetail(childs.title)"
              ></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list-group>

        <v-list-item @click="gotoKnow()">
          <v-list-item-icon>
            <v-icon>mdi-rhombus-split</v-icon>
          </v-list-item-icon>
          <v-list-item-title>KnowledgeBase</v-list-item-title>
        </v-list-item>
        <v-list-item @click="gotoSupport()">
          <v-list-item-icon>
            <v-icon>mdi-passport</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Support</v-list-item-title>
        </v-list-item>
        <v-list-item @click="gotoDownload()">
          <v-list-item-icon>
            <v-icon>mdi-star-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Download</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :color="barColor"
      light
      v-show="$vuetify.breakpoint.mdAndUp"
      fixed
      v-scroll="onScroll"
      flat
    >
      <v-list-item class="col-md-4 col-lg-6">
        <v-img
          style="cursor:pointer"
          @click="gohome()"
          max-width="100"
          src="../img/logo_WH_750.png"
        ></v-img>
      </v-list-item>
      <v-spacer></v-spacer>

      <v-list-item class="col-md-6 col-lg-4 justify-end">
        <v-hover v-slot="{ hover }">
          <v-card color="transparent" flat>
            <v-btn rounded text dark> Products</v-btn>
            <v-expand-transition>
              <v-card
                elevation="20"
                tile
                v-if="hover"
                class=" white v-card--reveal text-h2"
              >
                <v-row class="pa-10">
                  <div
                    v-for="item in items"
                    :key="item.title"
                    style="width:100%"
                  >
                    <div>
                      <v-tabs
                        class="d-flex justify-center"
                        v-model="tab"
                        color="success"
                      >
                        <v-tabs-slider color="success"></v-tabs-slider>
                        <v-tab
                          v-for="itemone in item.items"
                          :key="itemone.title"
                          @click="changeTab(itemone.title)"
                        >
                          {{ itemone.title }}</v-tab
                        >
                      </v-tabs>
                    </div>
                    <div
                      v-for="itemone in item.items"
                      :key="itemone.title"
                      v-show="itemone.title == selectedTab"
                      style="flex-direction:row;display:flex;justify-content:center;margin-top:20px"
                    >
                      <v-card
                        v-for="one in itemone.items"
                        :key="one.title"
                        class="pa-10 ma-5 justify-center text-center"
                        style="cursor:pointer"
                        @click="gotoDetail(one.title)"
                        :elevation="0"
                        outlined
                      >
                        <svg class="icon ma-5" aria-hidden="true">
                          <use :xlink:href="one.icon"></use>
                        </svg>
                        <p class="text-h6">{{ one.title }}</p>
                      </v-card>
                    </div>
                  </div>
                </v-row>
              </v-card>
            </v-expand-transition>
          </v-card>
        </v-hover>
        <v-btn rounded text dark @click="gotoKnow()"> KnowledgeBase </v-btn>
        <v-btn rounded text dark @click="gotoSupport()"> Support </v-btn
        ><v-btn rounded text dark @click="gotoDownload()">
          Download</v-btn
        ></v-list-item
      >
    </v-app-bar>
    <v-main>
      <v-container fluid class="ma-0 pa-0">
        <router-view />
      </v-container>
    </v-main>
    <!-- <v-btn
      fixed
      bottom
      right
      flat
      color="success"
      style="z-index:99"
      elevation="1"
      ><v-icon style="padding-right:10px">mdi-pencil</v-icon>message</v-btn
    > -->
    <v-btn
      fixed
      bottom
      right
      flat
      color="success"
      style="z-index:99"
      elevation="1"
      fab
      href="mailto:support@top0.com"
      ><v-icon>mdi-email</v-icon></v-btn
    >
    <transition name="slide-fade">
      <v-btn
        fixed
        right
        flat
        dark
        color="blue darken-3"
        style="z-index:99;bottom:90px"
        elevation="1"
        fab
        v-if="topbtn"
        @click="$vuetify.goTo(0, options)"
        ><v-icon>mdi-chevron-up</v-icon></v-btn
      ></transition
    >
    <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">We'd love to hear from you.</span>
          <span class="text-subtitle-2"
            >Send us a message and we'll respond as soon as possible</span
          >
        </v-card-title>

        <v-divider class="mt-1"></v-divider>

        <v-card-text>
          <v-container>
            <v-row class="pt-10">
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="First Name"
                  outlined
                  v-model="fname"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Last Name"
                  outlined
                  v-model="lname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Email"
                  prepend-inner-icon="mdi-email"
                  outlined
                  required
                  :rules="emailRules"
                  v-model="_email"
                  suffix="*"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-radio-group v-model="rval" row mandatory>
                  <v-radio label="After sales problems" value="1"></v-radio>
                  <v-radio label="Pre sales consultation" value="0"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" class="pa-0">
                <v-select
                  label="Product Type"
                  item-text="typename"
                  item-value="typeid"
                  :items="ptItems"
                  dense
                  v-model="ptSelected"
                  outlined
                  @change="pmChange()"
                ></v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-select
                  label="Product Model"
                  item-text="productmodel"
                  :items="pmItems"
                  dense
                  v-model="pmSelected"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Serial Number"
                  outlined
                  v-model="snNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Third party platform order code"
                  outlined
                  v-model="tcode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="what do you want to tell us?"
                  value=""
                  v-model="text_area"
                  :rules="msgRules"
                  required
                  suffix="*"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  dense
                  label="Where are you from?"
                  outlined
                  v-model="con"
                ></v-text-field> </v-col
              ><v-col cols="12" class="pa-0"
                ><v-btn
                  color="blue darken-1"
                  outlined
                  href="mailto:support@top0.com"
                  small
                  ><v-icon left> mdi-email-fast</v-icon>
                  Or click here to send us an email
                </v-btn></v-col
              >

              <p class="caption font-italic">* means this option is Required</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider class="mt-1"></v-divider>
        <v-card-actions style="position:relative">
          <v-alert
            style="position:fixed;bottom:20px"
            v-model="alert"
            dense
            color="red"
            type="info"
            border="left"
            transition="scroll-y-reverse-transition"
            dismissible
            >{{ alertmsg }}</v-alert
          >
          <v-alert
            style="position:fixed;bottom:20px"
            v-model="sendAlert"
            dense
            color="success"
            type="success"
            border="left"
            transition="scroll-y-reverse-transition"
            dismissible
            >{{ sendmsg }}</v-alert
          >

          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeMsg()">
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="sendMsg()"
            :loading="issendMsg"
          >
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-footer dark padless>
      <v-row style="background-color:#252a2c">
        <v-col class="col-md-3 col-sm-6 pa-10 " cols="12"
          ><div class="justify-center d-flex">
            <img src="../img/logo_WH_750.png" style="width:50%;" />
          </div>

          <p class="text-caption text-center">
            <v-icon color="success">mdi-map-marker</v-icon>100 N HOWARD ST STE
            R, SPOKANE, WA, 99201, UNITED STATES
          </p>
          <div class="justify-center d-flex">
            <svg
              class="icon ma-5 twicon"
              aria-hidden="true"
              style="font-size:1.5em"
              @click="gotoTw()"
            >
              <use :xlink:href="'#icon-twitter'"></use>
            </svg>
            <svg
              class="icon ma-5 fbicon"
              aria-hidden="true"
              style="font-size:1.5em"
            >
              <use :xlink:href="'#icon-facebook'"></use>
            </svg>
            <svg
              class="icon ma-5 insicon"
              aria-hidden="true"
              style="font-size:1.5em"
            >
              <use :xlink:href="'#icon-instagram'"></use>
            </svg>
            <svg
              class="icon ma-5 yticon"
              aria-hidden="true"
              style="font-size:1.5em"
              @click="gotoYb()"
            >
              <use :xlink:href="'#icon-youtube'"></use>
            </svg>
          </div>
        </v-col>
        <v-col
          class="col-md-9  col-sm-6  justify-center align-center d-flex text-center"
          cols="12"
        >
          <v-row>
            <v-col class="col-md-3"
              ><v-btn class="mt-2" text @click="gotoKnow()">
                KnowledgeBase
              </v-btn></v-col
            >
            <v-col class="col-md-3"
              ><v-btn class="mt-2" text @click="gotoDownload()">
                Downloads
              </v-btn></v-col
            ><v-col class="col-md-3"
              ><v-btn class="mt-2" text @click="gotoWarranty()">
                Warranty</v-btn
              ></v-col
            ><v-col class="col-md-3"
              ><v-btn class="mt-2" text @click="gotoAbout()">
                About us
              </v-btn></v-col
            >
          </v-row>
        </v-col>
        <v-card
          flat
          class="white--text text-center pa-0"
          style="background-color:#252a2c;width:100%"
        >
          <v-card-text class="white--text pt-0">
            <!-- Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet.
            Mauris cursus commodo interdum. Praesent ut risus eget metus luctus
            accumsan id ultrices nunc. Sed at orci sed massa consectetur
            dignissim a sit amet dui. Duis commodo vitae velit et faucibus.
            Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum
            ultrices, cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel
            diam elementum tempor vel ut orci. Orci varius natoque penatibus et
            magnis dis parturient montes, nascetur ridiculus mus. -->
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            <strong>©2022 top0. All rights reserved</strong>
          </v-card-text>
        </v-card>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import axios from "axios";
export default {
  name: "App",

  components: {},
  data: () => ({
    //
    sendAlert: false,
    sendmsg: null,
    alertmsg: null,
    alert: false,
    msgRules: [
      (v) => !!v || "Message is required",
      (v) => v.length > 20 || "Message must be more than 20 characters",
    ],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+/.test(v) || "E-mail must be valid",
    ],
    pmSelected: "",
    ptItems: null,
    pmItems: null,
    issendMsg: false,
    text_area: "",
    fname: "",
    lname: "",
    _email: "",
    tcode: "",
    con: "",
    snNumber: "",
    dialog: false,
    isHome: 1,
    drawer: false,
    barColor: "#212121",
    selectedItem: 0,
    tab: null,
    rval: 1,
    selectedTab: "SDI Camera",
    topbtn: false,
    items: [
      {
        active: true,
        items: [
          {
            title: "SDI Camera",
            items: [
              // { title: "T7E-SDI", icon: "#icon-T7E" },
              { title: "T7S-SDI", icon: "#icon-T7S" },
              {title:"T7S-NDI",icon:"#icon-T7S"},
              { title: "T7F-NDI", icon: "#icon-T7P" },
              { title: "T7X-4K", icon: "#icon-T7X" },
            ],
          },{
            title: "NDI Camera",
            items: [
              // { title: "RC26", icon: "#icon-RC26" },
              
              // { title: "T7E-NDI", icon: "#icon-T7P" }
            {title:"T7S-NDI",icon:"#icon-T7S"},{
              title: "T7F-NDI", icon: "#icon-T7P" 
            } ,{ title: "T7X-4K", icon: "#icon-T7X" }],
          },
          // {
          //   title: "HDMI Camera",
          //   items: [
          //     { title: "T7E-SDI", icon: "#icon-T7E" },
          //     { title: "T7S-SDI", icon: "#icon-T7S" },
          //     { title: "T7F-NDI", icon: "#icon-T7P" },
          //     // {
          //     //   title: "RC26",
          //     //   icon: "#icon-RC26",
          //     // },
          //     { title: "T7X-4K", icon: "#icon-T7X" },
          //   ],
          // },
          {
            title: "4K Camera",
            items: [
              { title: "T7X-4K", icon: "#icon-T7X" },
              // { title: "T7W-4KU3-2", icon: "#icon-T7W-4KU3-2" },
            ],
          },
          {
            title: "USB Camera",
            items: [
              // { title: "RC26", icon: "#icon-RC26" },
              { title: "T7X-4K", icon: "#icon-T7X" },
              // { title: "T7W-U2-2", icon: "#icon-T7W-U2-2" },
              // { title: "T7W-4KU3-2", icon: "#icon-T7W-4KU3-2" },
            ],
          },
          
          
        ],
        title: "Products",
      },
    ],
  }),
  mounted() {
    var that = this;
    // that.$router.push({
    //   path: "/home",
    // });
    that.getProductType();
    window.addEventListener("scroll", () => {
      let top =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      if (top > 100) {
        this.topbtn = true;
      } else {
        this.topbtn = false;
      }
    });
  },
  methods: {
    changeTab(title) {
      this.selectedTab = title;
    },
    gohome() {
      this.$router.push({
        path: "/",
      });
    },
    gotoAbout() {
      this.$router.push({
        path: "/about",
      });
    },
    gotoDetail(type) {
      var that = this;
      if (type == "RC26") {
        that.$router.push({ path: "/Detail26" });
      } else if (type == "T7P-SDI") {
        that.$router.push({ path: "/Detail7p" });
      } else if (type == "T7S-SDI") {
        that.$router.push({ path: "/Detail7s" });
      } else if (type == "T7E-SDI") {
        that.$router.push({
          path: "/Detail",
        });
      } else if (type == "T7F-NDI") {
        that.$router.push({
          path: "/Detail7fndi",
        });
      } else if (type == "T7X-4K") {
        that.$router.push({
          path: "/Detail7x4k",
        });
      }else if (type == "T7S-NDI") {
        that.$router.push({
          path: "/Detail7sndi",
        });
      }
       else {
        alert("Coming soon");
      }
    },
    gotoContact() {
      this.$router.push({
        path: "Contact",
      });
    },
    showddd() {
      this.getProductType();
      this.dialog = true;
    },
    gotoDownload() {
      this.$router.push({
        path: "/Download",
      });
    },
    gotoSupport() {
      this.$router.push({
        path: "/Support",
      });
    },
    gotoKnow() {
      this.$router.push({
        path: "/Knowledge",
      });
    },
    gotoWarranty() {
      this.$router.push({
        path: "/Warranty",
      });
    },
    //获取产品类型
    getProductType() {
      var that = this;

      axios.get("api/ProductInfo/GetProductTypeList").then(function(response) {
        that.ptItems = response.data.data;
      });
    },
    pmChange() {
      var that = this;
      axios
        .get("api/ProductInfo/GetProductListByTypeId?typeid=" + that.ptSelected)
        .then(function(response) {
          that.pmItems = response.data.data;
        });
    },
    sendMsg() {
      var that = this;
      that.issendMsg = true;
      if (that._email == "" || that.text_area == "") {
        that.alertmsg = "some options are required";
        that.alert = true;
        that.issendMsg = false;
      } else {
        let params = new FormData();
        params.append("firstname", that.fname);
        params.append("lastname", that.lname);
        params.append("email", that._email);
        params.append("isaftersale", parseInt(that.rval));
        params.append("orderid", that.tcode);
        params.append("productmodel", that.pmSelected);
        params.append("remarkcontent", that.text_area);
        params.append("serialnumber", that.snNumber);
        params.append("country", that.con);
        axios.post("api/Remark/Add", params).then(function(response) {
          if (response.data.code == 1) {
            that.content = response.data.data;
            that.issendMsg = false;
            that.sendmsg = "send message successful";
            that.sendAlert = true;
          }
        });
      }
    },
    closeMsg() {
      this.dialog = false;
      this.fname = "";
      this.lname = "";
      this._email = "";
      this.tcode = "";
      this.pmSelected = "";
      this.text_area = "";
      this.con = "";
      this.snNumber = "";
    },
    gotoTw() {
      window.open("https://twitter.com/top0Tech");
    },
    gotoYb() {
      window.open("https://www.youtube.com/channel/UC16pLqBFE_WgEspMhkkATKQ");
    },
  },
};
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  top: 64px;
  left: 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 999;
}
.twicon:hover {
  cursor: pointer;
  color: #42a5f5;
}
.fbicon:hover {
  cursor: pointer;
  color: #5c6bc0;
}
.insicon:hover {
  cursor: pointer;
  color: #ab47bc;
}
.yticon:hover {
  cursor: pointer;
  color: #ef5350;
}
</style>
