import Vue from 'vue'
import Router from 'vue-router'
import FristPage from "@/components/FristPage"
import Products from "@/components/Products"
import Detail from "@/components/Detail"
import Detail26 from "@/components/Detail26"
import Support from "@/components/Support"
import Knowledge from "@/components/Knowledge"
import Artical from "@/components/Artical"
import Contact from "@/components/Contact"
import Warranty from "@/components/Warranty"
import Download from "@/components/Download"
import About from "@/components/About"
import Detail7p from "@/components/Detail7p"
import Detail7s from "@/components/Detail7s"
import Detail7f from "@/components/Detail7fndi"
import Detail7x from "@/components/Detail7x4k"
import Detail7sndi from "@/components/Detail7sndi"

Vue.use(Router)

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: FristPage

    },
    {
      path: "/home",
      component: FristPage

    }, {
      path: "/products",
      component: Products
    }, {
      path: "/Detail",
      component: Detail
    }, {
      path: "/Support",
      component: Support,
      
    }, {
      path: '/Knowledge',
      component: Knowledge
    }, {
      path: '/Artical/:id',
      component: Artical 
    }, {
      path: '/Contact',
      component:Contact
    }, {
      path: '/Warranty',
      component:Warranty
    }, {
      path: '/Download',
      component:Download
    }, {
      path: '/Detail26',
      component:Detail26
    }, {
      path: '/About',
      component:About
    }, {
      path: '/Detail7p',
      component:Detail7p
    }, {
      path: '/Detail7s',
      component:Detail7s
    },{
      path:'/Detail7fndi',
      component:Detail7f
    },{
      path:'/Detail7x4k',
      component:Detail7x
    },{
      path:'/Detail7sndi',
      component:Detail7sndi
    }
  ]
})
