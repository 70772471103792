<template>
  <div style="padding-top: 64px;padding-bottom:200px;" class="flex-column">
    <v-row class="justify-center pl-16 pr-16">
      <v-col class="col-md-6 text-left pa-0" cols="12" style="margin-top:40px"
        ><p>How can we help you today?</p>

        <v-text-field
          v-model="searchtext"
          flat
          outlined
          label="Enter you search terms here"
          prepend-inner-icon="mdi-magnify"
          hide-details
          solo
          @input="showSearch()"
          @focus="showSearch()"
          @click="showSearch()"
          @blur="hideSearch()"
          :loading="isSearchload"
        ></v-text-field>
        <transition name="bounce">
          <div style="position:relative" v-show="isSearch">
            <v-card
              class="mx-auto"
              tile
              style="position:absolute;z-index:10;top:10px;width:100%;height: 400px;overflow: auto;"
              elevation="20"
              dark
            >
              <v-list dense flat >
                <v-subheader
                  >Search
                  <span style="padding:0 4px;color:#66BB6A;font-weight:bold">{{
                    count
                  }}</span>
                  Results</v-subheader
                >
                <v-list-item-group v-model="selectedItem" color="success">
                  <v-list-item
                    two-line
                    v-for="art in searchArt"
                    :key="art.articleid"
                    @click="readArtical(art.articleid)"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{
                        art.articletitle
                      }}</v-list-item-title>
                      <v-list-item-subtitle>{{
                        art.updatetime
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </div>
        </transition>
      </v-col>
    </v-row>
    <h1
      class="text-h4 font-weight-bold text-center pa-10"
      style="color:#1E88E5"
    >
      Knowledge Base
    </h1>
    <div class="loadEffect" v-show="isload">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <v-dialog v-model="dialog" scrollable max-width="650px">
      <v-card>
        <v-card-title class="text-h6 grey darken-4" style="color:#fff"
          >Please choose one article below?</v-card-title
        >
        <v-divider></v-divider>
        <v-card-text style="height: 300px;" class="pa-0">
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              v-for="d in doclist"
              :key="d"
              dense
              @click="readArtical(d.articleid)"
            >
              <v-list-item-icon>
                <v-icon color="primary">mdi-text-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-left"
                  v-text="d.articletitle"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="col-md-9 mx-auto" cols="12">
      <v-row class="justify-start align-start pl-4 pr-4">
        <v-col
          class="col-md-6 text-center"
          cols="12"
          v-for="item in typeList"
          :key="item.typename"
        >
          <v-card class="mx-auto" outlined min-height="280">
            <v-list-item>
              <v-list-item-content>
                <div class="text-overline">
                  {{ item.typename }}
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item
                v-for="(artlist, i) in item.articleList.slice(0, 5)"
                :key="i"
                dense
                @click="readArtical(artlist.articleid)"
              >
                <v-list-item-icon>
                  <v-icon color="primary">mdi-text-box-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="text-left"
                    v-text="artlist.articletitle"
                  ></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
            <div class="text-right" v-show="item.articleList.length >= 5">
              <v-btn
                text
                small
                color="yellow darken-4"
                @click="more(item.articleList)"
                >See all {{ item.articleList.length }} articles</v-btn
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      typeList: null,
      dialog: false,
      doclist: null,
      isload: false,
      searchtext: "",
      searchArt: null,
      isSearch: false,
      isSearchload: false,
      selectedItem: null,
      count: "",
    };
  },
  mounted() {
    this.getPhoneNum();
    this.isload = true;
    window.addEventListener("scroll", () => {
      this.isSearch = false;
    });
  },
  methods: {
    getPhoneNum() {
      var that = this;
      axios
        .get("api/Knowledge/GetTypeList?hasContent=1")
        .then(function(response) {
          if (response.data.code == 1) {
            console.log(response.data);
            that.typeList = response.data.data;
            that.isload = false;
          } else {
            that.typeList = response.data.msg;
          }
        });
    },
    more(_doclist) {
      var that = this;
      that.dialog = true;
      that.doclist = _doclist;
    },
    readArtical(artID) {
      this.$router.push({
        path: `/Artical/${artID}`,
      });
    },
    showSearch() {
      var that = this;
      that.isSearchload = true;
      if (that.searchtext != "") {
        axios
          .get("api/Knowledge/SearchArticleList?key=" + that.searchtext)
          .then(function(response) {
            if (response.data.code == 1) {
              that.isSearch = true;
              that.isSearchload = false;
              // that.searchArt = response.data.data.slice(0, 5);
              that.searchArt = response.data.data;
              that.count = response.data.data.length;
            } else {
              that.searchArt = null;
              that.isSearch = false;
              that.isSearchload = false;
            }
          });
      } else {
        that.isSearch = false;
        that.searchArt = null;
        that.isSearchload = false;
      }
    },
    hideSearch() {
      var that = this;
      setTimeout(() => {
        that.isSearch = false;
        that.isSearchload = false; //娃娃消失
      }, 100);
    },
  },
};
</script>
<style scoped>
.bounce-enter-active {
  animation: bounce-in 0.5s;
  z-index: 5;
}
/* .bounce-leave-active {
  animation: bounce-in 0.5s reverse;
  z-index: 5;
} */
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.loadEffect {
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
  margin-top: 0px;
}
.loadEffect span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #43a047;
  position: absolute;
  -webkit-animation: load 1.04s ease infinite;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
.loadEffect span:nth-child(1) {
  left: 0;
  top: 50%;
  margin-top: -8px;
  -webkit-animation-delay: 0.13s;
}
.loadEffect span:nth-child(2) {
  left: 14px;
  top: 14px;
  -webkit-animation-delay: 0.26s;
}
.loadEffect span:nth-child(3) {
  left: 50%;
  top: 0;
  margin-left: -8px;
  -webkit-animation-delay: 0.39s;
}
.loadEffect span:nth-child(4) {
  top: 14px;
  right: 14px;
  -webkit-animation-delay: 0.52s;
}
.loadEffect span:nth-child(5) {
  right: 0;
  top: 50%;
  margin-top: -8px;
  -webkit-animation-delay: 0.65s;
}
.loadEffect span:nth-child(6) {
  right: 14px;
  bottom: 14px;
  -webkit-animation-delay: 0.78s;
}
.loadEffect span:nth-child(7) {
  bottom: 0;
  left: 50%;
  margin-left: -8px;
  -webkit-animation-delay: 0.91s;
}
.loadEffect span:nth-child(8) {
  bottom: 14px;
  left: 14px;
  -webkit-animation-delay: 1.04s;
}
</style>
